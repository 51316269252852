import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../../redux/selectors";
import { LEVEL_ADMIN } from "../../utils/userPermissions";
import { usePermissions } from "../../context/permissionsContext";
import { fetchAllNotifications } from "../../api/NotificationsApi";
import { timeDifference } from "../../utils/generalHelper";
import { onMessageListener, requestPermission } from "../../firebaseConfig";
import { globalImages } from "../../utils/staticImages";

function Nav(props) {
  const { hasPermission } = usePermissions();
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const currentUser = useSelector((state) => getCurrentUser(state));

  const fetchNotifications = async () => {
    try {
      const response = await fetchAllNotifications();
      if (response.status) {
        setNotifications(response.data);
        const unread = response.data.filter(
          (notification) => !notification.read_at
        );
        setUnreadCount(unread.length);
      } else {
        console.error("Error fetching notifications:", response.message);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [currentUser.uuid]);

  useEffect(() => {
    requestPermission();

    onMessageListener()
      .then((payload) => {
        console.log("payload", payload);
        const newNotification = {
          title: payload?.data?.title,
          description: payload?.data?.body,
          created_at: payload?.data?.created_at,
          sender_profile_image: globalImages.r2f_logo,
          read_at: null,
        };

        setNotifications((prevNotifications) => [
          newNotification,
          ...prevNotifications,
        ]);
      })
      .catch((err) => {
        console.error("Error handling FCM message:", err);
      });
  }, []);

  return (
    <>
      <nav className="navbar navbar-top navbar-expand navbar-light bg-primary border-bottom">
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav align-items-center ml-md-auto">
              <li className="nav-item d-xl-none">
                <div
                  className="pr-3 sidenav-toggler sidenav-toggler-dark"
                  data-action="sidenav-pin"
                  data-target="#sidenav-main"
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="navbar-nav align-items-center ml-auto ml-md-0">
              <li className="nav-item mb-0 text-sm">
                Questions? Email:
                <a
                  href="mailto:support@racetofreedom.com"
                  style={{ color: "#525f7f" }}
                >
                  support@racetofreedom.com
                </a>
              </li>

              {/* Notification Dropdown */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link pr-0 pl-0"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div
                    className="media align-items-center pr-0 pl-3"
                    style={{ position: "relative", padding: "5px" }}
                  >
                    <div className="media-body mr-3 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {currentUser.name}
                      </span>
                    </div>
                    <span className="avatar avatar-sm rounded-circle bell-icon mr-1 bg-default">
                      <>
                        {unreadCount > 0 && (
                          <span className="notification-badge-outer">
                            {unreadCount > 99 ? "99+" : unreadCount}
                          </span>
                        )}
                        <i
                          style={{ fontSize: 22 }}
                          className="fa fa-bell-o text-white"
                        ></i>
                      </>
                    </span>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-right px-3 scroll-view pt-2">
                  <div className="dropdown-header noti-title pl-1">
                    <h3 className="text-overflow m-0">Notifications</h3>
                  </div>
                  <div className="dropdown-divider"></div>
                  {unreadCount > 0 ? (
                    notifications
                      .filter((notification) => !notification.read_at)
                      .map((notification, index) => (
                        <Link
                          key={index}
                          // to="/{post_uuid}/notifications"
                          to="/notifications"
                          style={{ backgroundColor: "#F3FAFF" }}
                          className="dropdown-item d-start pl-0 my-2"
                        >
                          <div className="container-fluid px-4 d-block">
                            <div className="row">
                              <div className="col-1 px-0 d-start">
                                <div className="media align-items-center">
                                  <span className="avatar avatar-sm rounded-circle">
                                    <img
                                      src={
                                        notification.sender_profile_image
                                          ? notification.sender_profile_image
                                          : globalImages.r2f_logo
                                      }
                                      style={{
                                        objectFit: "cover",
                                        aspectRatio: "1",
                                        position: "relative",
                                        marginRight: 0,
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="col-11 text-left">
                                <div className="d-space-between">
                                  <span
                                    className="text-xs font-weight-bolder"
                                    style={{ color: "#555F7C" }}
                                  >
                                    {notification.title}
                                  </span>
                                  <span
                                    className="text-xs"
                                    style={{
                                      color: "#555F7C",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {timeDifference(notification.created_at)}
                                  </span>
                                </div>
                                <span
                                  className="text-xs pr-3"
                                  style={{ color: "#555F7C" }}
                                >
                                  {notification?.description?.length > 43
                                    ? `${notification?.description?.slice(
                                        0,
                                        41
                                      )}...`
                                    : notification?.description}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))
                  ) : (
                    <span>No notifications</span>
                  )}
                </div>
              </li>

              {/* Profile and settings dropdown */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link pr-0 pl-0"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div
                    className="media align-items-center"
                    style={{ position: "relative", padding: "5px" }}
                  >
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        src={currentUser.avatar}
                        style={{
                          objectFit: "cover",
                          aspectRatio: "1",
                          position: "relative",
                        }}
                      />
                    </span>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <div className="dropdown-header noti-title">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </div>
                  <Link to="/account" className="dropdown-item">
                    <i className="ni ni-single-02"></i>
                    <span>My profile</span>
                  </Link>
                  {!hasPermission(LEVEL_ADMIN) && (
                    <>
                      <Link to="/account/payment" className="dropdown-item">
                        <i className="ni ni-calendar-grid-58"></i>
                        <span>Payment</span>
                      </Link>
                    </>
                  )}
                  {!unreadCount > 0 && (
                    <Link to="/notifications" className="dropdown-item">
                      <i className="fa fa-bell"></i>
                      <span>All Notifications</span>
                    </Link>
                  )}
                  <Link to="/support" className="dropdown-item">
                    <i className="ni ni-support-16"></i>
                    <span>Support</span>
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link
                    onClick={props.handleLogout}
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-user-run"></i>
                    <span>Logout</span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Nav;
